h1, .h1{
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  line-height: 34px;
  text-transform: uppercase;
  color: $base-300;

  @include breakpoint(sm){
	font-size: 32px;
	line-height: 45px;
  }

  @include breakpoint(lg){
	font-size: 40px;
	line-height: 56px;
  }

  @include breakpoint(xl){
	font-size: 44px;
	line-height: 62px;
  }
}

h2, .h2{
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.2;
  color: $base-300;

  @include breakpoint(sm){
	font-size: 32px;
  }
}

h3, .h3{
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.4;
  color: $base-300;

  @include breakpoint(sm){
	font-size: 20px;
  }
}

h4, .h4{
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  font-style: normal;
  color: $base-300;
}

h5, .h5{
  font-size: 1.125rem;
  line-height: 1.45;
  font-style: normal;
  color: $base-300;
}

h6, .h6{
  font-size: 1rem;
  line-height: 1.5;
  font-style: normal;
}


