.gallery{

  &__slider{

	.swiper-slide{

	  @include breakpoint(xl){
		width: 860px;
	  }

	  img{
		width: 100%;
		display: block;
	  }
	}

	@include breakpoint(xl){
	  .swiper-button-prev{
		left: calc(50% - 480px);
	  }

	  .swiper-button-next{
		right: calc(50% - 480px);
	  }
	}
  }
}