.hero{
  height: 100vh;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: url("../../assets/img/hero_banner_mob.jpg")no-repeat center;
  background-size: cover;
  padding: 60px 0;

  @include breakpoint(lg){
	background: url("../../assets/img/waiter1.jpg")no-repeat top center;
	background-size: cover;
	justify-content: center;
  }

  &__title{
	color: $white;
	max-width: 500px;
	margin-bottom: 20px;

	@include breakpoint(lg){
	  margin-bottom: 30px;
	}
  }

  &__description{
	font-size: 18px;
	letter-spacing: 0.5px;
	margin-bottom: 30px;
	max-width: 260px;
	line-height: 1.55;

	@include breakpoint(sm){
	  font-size: 20px;
	  max-width: 370px;
	}

	@include breakpoint(lg){
	  font-size: 24px;
	  max-width: 430px;
	  margin-bottom: 40px;
	}
  }
}