.about{

  &__icon{
	width: 70px;
	height: 70px;
	display: block;
	flex-shrink: 0;
  }

  &__description{

	&_img{
	  height: 260px;

	  @include breakpoint(sm){
		height: 300px;
	  }

	  @include breakpoint(lg){
		height: 435px;
	  }
	}
  }
}