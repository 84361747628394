.blog-detail{
  color: $base-400;

  &__container{
	max-width: 760px;
  }

  &__author{
	width: 30px;
	height: 30px;
	border-radius: 50%;
	flex-shrink: 0;
	overflow: hidden;
	margin-right: 10px;

	img{
	  display: block;
	  width: 100%;
	}
  }

  &__category{
	color: rgba(0, 0, 0, .5);
    font-size: 10px;
	letter-spacing: 1.5px;
	line-height: normal;
	font-weight: 600;
	text-transform: uppercase;
  }

  &__articles{
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	font-size: 12px;

	@include breakpoint(sm){
	  font-size: 14px;
	}

	li{
	  display: flex;
	  gap: 1rem;
	  align-items: flex-start;
	}

	&_img{
	  width: 60px;
	  height: 75px;
	  flex-shrink: 0;
	  position: relative;
	  display: block;
	  overflow: hidden;
	  transition: 0.5s all ease;

	  @include breakpoint(sm){
		width: 100px;
	  }

	  &:hover{
		img{
		  transform: scale(1.1);
		}
	  }

	  img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		transition: 0.5s all ease;
	  }
	}

	&_category{
	  color: rgba(0, 0, 0, .5);
	  font-size: 9px;
	  letter-spacing: 1px;
	  line-height: normal;
	  font-weight: 600;
	  text-transform: uppercase;
	  margin-bottom: 5px;
	}

	&_title{
	  transition: 0.5s all ease;
	  margin-bottom: 0.5rem;
	  font-size: 1rem;

	  @media screen and (max-width: 640px){
		font-size: 12px;
	  }

	  &:hover{
		color: $accent-400;
	  }
	}

	&_description{
	  font-weight: 300;
	}
  }
}