.advantages{

  &__item{
	position: relative;
	display: flex;
	align-items: flex-start;
	gap: 1.5rem;

	@include breakpoint(sm){
	  align-items: center;
	}

	@include breakpoint(md){
	  padding-bottom: 2rem;
	}

	&_index{
	  position: relative;
	  color: #e0e0e0;
	  font-weight: 700;
	  font-size: 100px;
	  line-height: 0.9;
	  min-width: 70px;
	  text-align: right;
	  flex-shrink: 0;

	  @include breakpoint(sm){
		min-width: 175px;

		&:before{
		  content: '';
		  position: absolute;
		  bottom: 5px;
		  right: 70px;
		  background-color: #e0e0e0;
		  width: 300px;
		  height: 5px;
		}
	  }

	  @include breakpoint(md){
		font-size: 200px;
		position: absolute;
		top: -40px;
		right: 0;

		&:before{
		  display: none;
		}
	  }
	}
  }
}