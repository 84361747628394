.inner-container{
  border-radius: 30px;
  background-color: #ffffff;

  &__img{
    box-shadow: 0 10px 15px 0 rgba($base-300, 0.3);
	border-radius: 30px;
	overflow: hidden;
	position: relative;
	flex-shrink: 0;

	&--overlay{

	  &:before{
		content: '';
		background: $base-300;
		opacity: 0.3;
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
	  }
	}

	img{
	  display: block;
	  width: 100%;
	  height: 100%;
	  object-position: center;
	  object-fit: cover;

	  &.manager-photo{
		@include breakpoint(xl){
		  max-height: 380px;
		  object-position: top;
		}
	  }
	}
  }
}
