.footer{
  background-color: $base-500;
  color: #e0e0e0;
  font-weight: 300;
  text-align: center;
  font-size: 0.875rem;

  &__nav{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 1rem 2rem;

	li{
	  a{
		font-size: 1rem;
		font-weight: 600;
		color: $white;
		transition: 0.5s all ease;
		  &.small{
			font-size: 0.875rem;
			  color: grey;
		  }

		&:hover{
		  color: $accent-400;
		}
	  }
	}
  }
}

.back-to-top{
  position: fixed;
  display: none;
  left: 20px;
  bottom: 20px;
  z-index: 98;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url("../../assets/img/arrow.svg")no-repeat;
  background-size: 100% 100%;
  cursor: pointer;

  @include breakpoint(sm){
	width: 50px;
	height: 50px;
	left: 30px;
	bottom: 30px;
  }
}