.main-nav{
  display: none;
  z-index: 98;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 20px;

  @include breakpoint(xl){
	display: block;
  }


  li{
	padding: 12.5px 0;
  }

  &__button{
	border: 2px solid #adadad;
	width: 13px;
	height: 13px;
	transition: .3s ease-in-out all;
	border-radius: 50%;
	position: relative;

	&.active, &:hover{
	  background: #8c8c8c;
	}

	&:hover{
	  .main-nav__tooltip{
		visibility: visible;
		opacity: 1;
		transform: translateX(0%) translateY(-50%);
	  }
	}
  }

  &__tooltip{
	background: $base-500;
	line-height: 2.5;
	border-radius: 3px;
	color: #ffffff;
	font-size: 14px;
	font-weight: 600;
	right: 33px;
	transform: translateX(-20%) translateY(-50%);
	top: 50%;
	visibility: hidden;
	opacity: 0;
	padding: 0 18px;
	position: absolute;
	white-space: nowrap;
	transition: .3s ease-in-out opacity, .3s ease transform, .3s ease visibility;

	&:after{
	  content: '';
	  position: absolute;
	  width: 0;
	  height: 0;
	  border: solid transparent;
	  border-width: 10px;
	  top: 50%;
	  right: -17px;
	  transform: translateY(-50%);
	  border-left-color: $base-500;
	}
  }
}