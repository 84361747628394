body{
  font-family: 'Ubuntu', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  background-color: $white;
  color: $base-200;
  font-size: 1rem;
}

a{
  color: $base-400;
  display: inline-block;
  text-decoration: none;
  line-height: 1.5;
  &:hover{
    color: $base-400;
  }
}

p{
  word-break: break-word;
  line-height: 1.5;
}

.accent_color-400{
  color: $accent-400!important;
}

.bg-base-100{
  background-color: $base-100!important;
}

[data-background-image] {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.quote{
  font-weight: 500;
  color: $accent-400;
  font-size: 50px;
  line-height: 0.8;

  @include breakpoint(lg){
	font-size: 100px;
  }
}

.progress-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 99;
}

.progress-bar {
  height: 100%;
  background-color: $accent-400;
  transition: all 0.6s ease;
}

.page-heading{
  min-height: 80px;
  background-color: $base-400;

  @include breakpoint(xxl){
	min-height: 100px;
  }
}