.blog{

  &__nav{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 0.5rem;

	li{

	  &.active, &:hover{
		a{
		  color: $base-100;
		  background-color: $base-400;
		}
	  }

	  a{
		padding: 6px 18px;
		border-radius: 40px;
		border: 1px solid $base-400;
		font-size: 12px;
		line-height: 1.35;
		text-align: center;
		transition: 0.5s all ease;

		@include breakpoint(md){
		  font-size: 14px;
		}

		@include breakpoint(xl){
		  font-size: 16px;
		}
	  }
	}
  }

  &__list{
	display: flex;
	gap: 1rem;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	position: relative;
	scroll-snap-align: start;
    margin-right: calc(-0.5* var(--bs-gutter-x));
    margin-left: calc(-0.5* var(--bs-gutter-x));
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);

    &::-webkit-scrollbar {
	  width: 10px;
	  height: 10px;
    }
    &::-webkit-scrollbar-thumb {
	  background: transparent;
	  border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
	  background: transparent;
    }

	@include breakpoint(sm){
	  display: grid;
	  grid-template-columns: repeat(2, 1fr);
	  gap: 2rem 1rem;
	}

	@include breakpoint(lg){
	  grid-template-columns: repeat(3, 1fr);
	  gap: 2rem;
	}

	@include breakpoint(xl){
	  gap: 3rem 2rem;
	}
  }

  &__card{
	min-width: 270px;
	max-width: 100%;

	@include breakpoint(sm){
	  min-width: auto;
	}

	&_img{
	  position: relative;
	  height: 237px;
	  margin-bottom: 1.25rem;
	  display: block;
	  width: 100%;
	  overflow: hidden;
	  transition: 0.5s all ease;

	  @include breakpoint(xl){
		height: 275px;
	  }

	  &:hover{
		img{
		  transform: scale(1.1);
		}
	  }

	  img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		transition: 0.5s all ease;
	  }

	}

	&_category{
	  padding: 7px 8px;
	  background-color: rgba(0, 0, 0, .5);
	  border-radius: 3px;
	  position: absolute;
	  top: 15px;
	  left: 15px;
	  max-width: calc(100% - 30px);
	  text-align: center;
	  color: #a8a8a8;
	  letter-spacing: 1px;
	  text-transform: uppercase;
	  line-height: normal;
	}

	&_title{
	  margin-bottom: 1rem;
	  transition: 0.5s all ease;

	  &:hover{
		color: $accent-400;
	  }
	}

	&_description{
	  font-weight: 300;
	  margin-bottom: 1.25rem;
	}

	&_date{
	  color: #a8a8a8;
	  font-weight: 500;
	}
  }
}

.tooltip-horizontal-scroll-icon_hand {
	animation: tooltip-horizontal-scroll-icon_anim-scroll-hand 2s infinite
}
.tooltip-horizontal-scroll-icon_card {
	animation: tooltip-horizontal-scroll-icon_anim-scroll-card 2s infinite
}
@keyframes tooltip-horizontal-scroll-icon_anim-scroll-hand {
  0% { transform: translateX(80px) scale(1); opacity: 0 }
  10% { transform: translateX(80px) scale(1); opacity: 1 }
  20%,60% { transform: translateX(175px) scale(.6); opacity: 1 }
  80% { transform: translateX(5px) scale(.6); opacity: 1 } to { transform: translateX(5px) scale(.6); opacity: 0 }
}
@keyframes tooltip-horizontal-scroll-icon_anim-scroll-card {
  0%,60% { transform: translateX(0) }
  80%,to { transform: translateX(-240px) }
}