.courses{
  &__item{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	transition: 0.5s all ease;
	cursor: pointer;

	@include breakpoint(md){
	  flex-direction: row;
	}

	@include breakpoint(xl){
	  flex-direction: column;
	}

	&:hover{
	  .courses__item_title{
		&:before{
		  opacity: 0.3;
		}
	  }
	}

	&_title{
	  min-height: 265px;
	  width: 100%;
	  flex-shrink: 0;
	  display: flex;
	  flex-direction: column;
	  justify-content: flex-end;

	  @include breakpoint(md){
		max-width: 300px;
	  }

	  @include breakpoint(xl){
		max-width: 100%;
	  }

	  &:before{
		content: '';
		background: $base-300;
		opacity: 0.3;
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		transition: 0.5s all ease;

		@include breakpoint(xl){
		  opacity: 1;
		}
	  }
	}

	&_description{
	  display: flex;
	  flex-direction: column;
	  flex-grow: 1;
	}

	&_button{
	  margin-top: auto;
	}
  }
}