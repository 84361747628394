.catering{

  &__list{
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.75rem;

	@include breakpoint(sm){
	  grid-template-columns: 1fr 1fr;
	  gap: 1rem;
	}

	@include breakpoint(lg){
	  grid-template-columns:1fr 1fr 1fr;
	  gap: 1.5rem;
	}

	@include breakpoint(xl){
	  grid-template-columns:1fr 1fr 1fr 1fr;
	  gap: 2rem;
	}
  }

  &__item{
	position: relative;
	border-radius: 30px;
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	transition: 0.5s all ease-in-out;

	@include breakpoint(sm){
	  height: 170px!important;
	}

	@include breakpoint(md){
	  height: 230px!important;
	}

	@include breakpoint(xl){
	  height: 200px!important;

	  &:hover{
		box-shadow: 0 10px 15px 0 rgba($base-300, 0.3);

		&:before{
		  opacity: 1;
		}

		.catering__item_title{
		  opacity: 1;
		  transform: translateY(0);
		}
	  }
	}

	&:before{
	  content: '';
	  background: $base-500;
	  opacity: 0.3;
	  top: 0;
	  left: 0;
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  z-index: 1;
	  transition: 0.5s all ease-in-out;

	  @include breakpoint(xl){
		opacity: 0;
	  }
	}

	&:last-child{
	  height: 230px;

	  @include breakpoint(lg){
		height: 400px!important;
		grid-column: 1 / -1;
	  }

	  @include breakpoint(xl){
		height: 100%!important;
		grid-column: 4;
		grid-row: 1 / span 3;

		&:before{
		  background: $main-400;
		}
	  }
	}

	&_title{
	  color: $white;
	  text-transform: uppercase;
	  text-align: center;
	  position: relative;
	  z-index: 2;
	  font-weight: 600;
	  transition: 0.5s all ease-in-out;

	  @include breakpoint(xl){
		opacity: 0;
		transform: translateY(30px);
	  }
	}
  }
}