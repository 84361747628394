.form-section{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: left top;

  @include breakpoint(lg){
	min-height: 600px;
	height: 80vh;
	background-attachment: fixed;
  }

  &__desktop-img{
	display: none;
	width: 100%;

	@include breakpoint(lg){
	  display: block;
	}
  }
}