$accent-400: #f0942b;

$main-400: #9c0f3c;

$base-500: #100904;
$base-400: #121316;
$base-300: #242424;
$base-200: rgba($base-300, 0.7);
$base-100: #ebebeb;

$border-color: #c9c9c9;

$black: #000;
$white: #fff;

