@import "../fonts/Ubuntu/stylesheet.css";
@import "bootstrap";
@import "swiper/swiper-bundle.css";
@import "/node_modules/flatpickr/dist/themes/material_orange.css";
@import "intl-tel-input/build/css/intlTelInput.css";
@import "/node_modules/lite-youtube-embed/src/lite-yt-embed.css";

@import './utils/mixin';
@import './utils/variable';

@import "./base/reset";
@import "./base/base";
@import "./base/typography";

@import './components/buttons';
@import './components/forms';
@import './components/social';
@import './components/post_content';
@import './components/bg-section';
@import './components/swiper_slider';
@import './components/popups';
@import './components/inner-container';

@import './layouts/header';
@import './layouts/footer';

@import './sections/about';
@import './sections/advantages';
@import './sections/blog';
@import './sections/blog-detail';
@import './sections/catering';
@import './sections/courses';
@import './sections/form-section';
@import './sections/gallery';
@import './sections/hero-section';
@import './sections/main-nav';
@import './sections/testimonials';

