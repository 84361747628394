.js_nav_open {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: rgba($base-400, 0.50);
  z-index: 99;
  transition: left 0.5s ease;
}

.hidden_body {
  overflow: hidden;

  .navbar_toggler{
	z-index: -1;
  }
}

.navbar_toggler{
  width: 48px;
  height: 48px;
  background: rgba($base-500, 0.5);
  border-radius: 50%;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 999;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include breakpoint(xxl){
    display: none;
  }
}

.header{
  display: flex;
  align-items: center;
  padding: 0 1rem;
  gap: 2rem;
  color: $white;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  min-height: 80px;

  @include breakpoint(xxl){
	min-height: 100px;
  }

  @media (min-width: 1600px){
	padding: 0 60px;

  }

  &__logo{
	width: 110px;
	height: 70px;
	flex-shrink: 0;

	@include breakpoint(xxl){
	  width: 150px;
	  height: 100px;
	}

	img{
	  display: block;
	  width: 100%;
	}
  }

  &__nav{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    transition: right .3s ease;
    right: -100%;
    top: 0;
    max-width: 260px;
    background: $base-400;
    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    padding: 42px 25px 25px;
	display: flex;
    flex-direction: column;
	gap: 24px;

	@include breakpoint(md){
	  padding: 48px 40px 38px;
	}

    @include breakpoint(xxl){
	  position: relative;
      right: auto;
	  top: auto;
	  overflow: initial;
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	  background: transparent;
	  flex-grow: 1;
	  width: auto;
	  height: auto;
	  padding: 0;
	  max-width: 100%;
	  gap: 30px;
    }

	@media (min-width: 1600px){
	  gap: 30px;
	}

    &.active {
      right: 0;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }

	&_close{
	  position: absolute;
	  top:2rem;
	  right: 1.25rem;
	  width: 1.5rem;
	  height: 1.5rem;
	  cursor: pointer;
	  z-index: 2;

	  @include breakpoint(xxl){
		display: none;
	  }
	}

	&_list{
	  display: flex;
	  flex-direction: column;
	  gap: 15px;

	  @include breakpoint(xxl){
		align-items: center;
		flex-direction: row;
		gap: 40px;
		margin: auto;
	  }

	  @media (min-width: 1600px){
		gap: 60px;
	  }

	  li{
		position: relative;
		cursor: pointer;

		&:hover{
		  &:after{
			transform: translateY(-50%) rotate(180deg)!important;
		  }
		}

		a, p{
		  color: $white;
		  transition: 0.5s all ease;
		  font-weight: 500;
		  position: relative;
		  z-index: 2;

		  &:hover{
			color: $accent-400;
		  }
		}

		&.submenu_show{
		  .submenu__title{
			display: none;
		  }

		  @include breakpoint(xxl){
			padding-right: 1rem;

			&:after{
			  content: '';
			  position: absolute;
			  right: 0;
			  top: 50%;
			  transform: translateY(-50%);
			  width: 0;
			  height: 0;
			  border-style: solid;
			  border-width: 4px 3px 0 3px;
			  border-color: transparent transparent transparent transparent;
			  border-top-color: inherit;
			  margin-top: 2px;
			}

			&:hover{
			  .header__nav_list-submenu{
				transition: 0.5s all ease-in-out;
				z-index: 1;
				visibility: visible;
				opacity: 1;
				transform: translate(-50%, 0);
			  }
			}

			.submenu__title{
			  display: block;
			}
		  }
		}
	  }

	  &-submenu {

		@include breakpoint(xxl){
		  width: 150px;
		  position: absolute;
		  left: 50%;
		  top: 0;
		  z-index: -1;
		  visibility: hidden;
		  opacity: 0;
		  transform: translate(-50%, 20px);
		  transition: 0.5s all ease-in-out;
		  padding-top: 2.5rem;
		}

		ul{
		  display: flex;
		  flex-direction: column;
		  gap: 15px;

		  @include breakpoint(xxl){
			background-color: $base-500;
			padding: 20px;
			gap: 10px;
			position: relative;

			&:after{
			  content: '';
			  position: absolute;
			  width: 0;
			  height: 0;
			  border: solid transparent;
			  border-width: 10px;
			  top: -19px;
			  left: 50%;
			  transform: translateX(-50%);
			  border-bottom-color: $base-500;
			}
		  }
		}
	  }
	}
  }

  &__callback{
	display: none;
	height: 40px;
	text-transform: none;
	width: 176px;
	font-size: 14px;

	@include breakpoint(xxl){
	  display: flex;
	}
  }

  &__social{
	gap: 10px;

	li{
	  a{
		opacity: 1;

		&:hover{
		  opacity: 0.8;
		}
	  }
	}

	&_description{
	  margin-top: auto;
	  font-size: 14px;

	  @include breakpoint(xxl){
		display: none;
	  }
	}
  }

  &__lang{
	display: flex;
	gap: 20px;

	li{
	  a, span{
		line-height: normal;
		color: $white;
		font-weight: 500;
	  }
	}
  }
}

