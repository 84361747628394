.testimonials{

  &__item{
	position: relative;
	margin-top: 75px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@include breakpoint(sm){
	  &:before{
		content: '';
		width: 60px;
		height: 60px;
		position: absolute;
		top: 16px;
		right: 16px;
		background: url("../../assets/img/testimonial.svg")no-repeat;
		background-size: 100% 100%;
	  }
	}

	@include breakpoint(md){
	  margin-top: 50px;
	}

	@include breakpoint(lg){
	  margin-left: 0!important;
	  margin-right: 0!important;
	  margin-top: 100px;

	  &:before{
		width: 70px;
		height: 70px;
	  }
	}

	&_wrap{
	display: flex;
	flex-direction: column;

	  @include breakpoint(md){

		&:nth-child(odd){
		  .testimonials__item{
			margin-left: 165px;

			&_photo{
			  top: 0;
			  left: -165px;
			  right: auto;
			}
		  }
		}

		&:nth-child(even){
		  .testimonials__item{
			margin-right: 165px;

			&_photo{
			  top: 0;
			  right: -165px;
			  left: auto;
			}
		  }
		}
	  }
	}

	&_photo{
	  box-shadow: 0 10px 15px rgba($base-400, 0.3);
	  width: 100px;
	  height: 100px;
	  border-radius: 50%;
	  overflow: hidden;
	  position: absolute;
	  top: -50px;
	  right: 50px;

	  @include breakpoint(sm){
		right: 0;
		left: 0;
		margin: auto;
	  }

	  @include breakpoint(md){
		width: 150px;
		height: 150px;
	  }

	  @include breakpoint(lg){
		right: auto!important;
		top: -75px!important;
		left: 40px!important;
	  }

	  img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	  }
	}

	&_name{
	  color: $accent-400;
	  font-weight: 600;
	  position: relative;
	  z-index: 2;

	  @include breakpoint(lg){
		margin-left: 170px;
	  }
	}
  }
}