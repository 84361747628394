.swiper-button-prev,
.swiper-button-next {
  color: $base-400;
  background-color: $white;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 1;
  border: 0;
  transition: 0.5s all ease;

  @include breakpoint(sm){
	width: 40px;
	height: 40px;
  }

  &:after{
	font-size: 14px;
	font-weight: bold;

	@include breakpoint(sm){
	  font-size: 18px;
	}
  }

  &:hover{
    background-color: $main-400;
	color: $white;
  }
}

.swiper-pagination{
  &-bullet{
    background: #c7c7c7;
    width: 0.5rem;
    height: 0.5rem;
	opacity: 1;
	margin: 0 2px!important;

    &-active{
      background: $main-400;
    }
  }
}

.swiper__nav{
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 1.25rem;

  &--center{
    justify-content: center;
  }

  &--left{
    justify-content: flex-start;
  }

  &--right{
    justify-content: flex-end;
  }

  .swiper-button-prev,
  .swiper-button-next{
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    margin-top: 0;
  }

  .swiper-pagination{
    position: relative;
    bottom: auto;
    left: auto;
    top: auto;
    right: auto;
    width: auto;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
  }
}

.swiper-slide{
  display: flex;
  height: auto;
}



