.bg-section{
  position: relative;
  color: $white;
  background: $base-500;

  &:before{
	content: '';
	background: $base-500;
	opacity: 0.3;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
  }

  &--dark{
	&:before{
	  opacity: 0.65;
	}
  }

  .form{

	&__input{
	  color: $white;
	}
  }
}