.form {

  [name='email_confirm'] {
    display: none;
  }

  &__input {
    display: block;
    width: 100%;
    border-radius: 30px;
    border: 2px solid $accent-400;
    background-color: transparent;
    padding: 0 1.25rem;
    font-family: "Ubuntu", sans-serif;
    height: 50px;

    &::placeholder {
      color: $base-100;
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &_calendar {
      background: url("../../assets/img/calendar.svg") no-repeat center right;
      background-size: 70px 25px;
      cursor: pointer;
    }
  }

  textarea {
    height: 5rem;
    resize: none;
    padding: 0.8rem 1.25rem;
    border-radius: 1.25rem;

    @include breakpoint(md) {
      height: 6.25rem !important;
    }
  }

  &__select, select {
    background-repeat: no-repeat;
    background-size: 50px 12px;
    background-position: center right;
    -webkit-appearance: none;
  }

  &__checkbox-input {
    display: block;
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
      visibility: hidden;

      &:checked + span {
        &:before, &:after {
          opacity: 1;
        }
      }
    }

    span {
      position: relative;
      padding-left: 2rem;
      font-size: 15px;
      font-weight: 300;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25rem;
        height: 1.25rem;
        border: 2px solid $main-400;
        background-color: $white;
        opacity: 0.7;
      }

      &:after {
        content: '';
        position: absolute;
        opacity: 0;
        left: 8px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $main-400;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  &__text-small {
    font-size: 13px;
    font-weight: 500;
  }
}

.quantity_swal {

  &__plus, &__minus {
    font-size: 26px;
    padding: 0 0.5rem;
    color: $main-400;
    cursor: pointer;
  }

  &__input {
    width: 70px;
    margin: 0 0.5rem;
    text-align: center;
    padding: 0;
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.iti {
  width: 100%;
}

.iti--inline-dropdown .iti__dropdown-content {
  color: #000;
}

.iti__selected-flag {
  padding: 0 6px 0 14px;
  background-color: transparent !important;
}