.button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  outline: none;
  border: 0;
  border-radius: 30px;
  color: $white;
  font-size: 14px;
  font-weight: 700;
  background: $accent-400;
  line-height: normal;
  transition: all 0.2s ease-in-out;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 5px 5px 20px 0 rgba(36, 36, 36, 0);
  height: 45px;
  width: 160px;

  @include breakpoint(sm){
	font-size: 16px;
	height: 50px;
	width: 180px;
  }

  @include breakpoint(lg){
	font-size: 18px;
	height: 55px;
	width: 200px;
  }

  &:hover{
	background: $main-400;
	color: $white;
  }

  &--main{
	color: $main-400;
	background-color: transparent;
	border: 2px solid $main-400;

	&:hover{
	  background: $main-400;
	  color: $white;
	}
  }

  &--white{
	color: $white;
	background-color: transparent;
	border: 2px solid $white;

	&:hover{
	  background-color: $white;
	  color: $base-500;
	}
  }
}


