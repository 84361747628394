span.bi-eye {
  color: #a8a8a8;
  font-weight: 500;
  float: right;
  text-align: right;

  &.visit-counter {
    text-align: left !important;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('../img/views.svg');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-right: 4px;
  }
}

.social {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;

  li {
    a {
      width: 32px;
      height: 32px;
      opacity: 0.8;
      transition: 0.5s all ease;

      &:hover {
        opacity: 1;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
}