body{
  .swal2-modal{
	width: 560px;
	padding: 100px 0 0;
	border-radius: 0;
	background: url("../../assets/img/logo-popup.svg")no-repeat center top #fff;
	background-size: 100% auto;

	@media screen and (min-width: 350px) {
	  padding-top: 130px;
	}

	@media screen and (min-width: 380px) {
	  padding-top: 160px;
	}

	@include breakpoint(sm){
	  padding-top: 186px;
	}
  }

  .swal2-content{
	color: $base-500;
	font-family: 'Ubuntu', Arial, sans-serif!important;
	padding: 0 16px 30px;
	text-align: left;

	@include breakpoint(sm){
	  padding: 0 40px 45px;
	}
  }

  .swal2-title{
	color: $base-500;
	margin-bottom: 1rem;
	font-size: 24px;

	@include breakpoint(sm){
	  font-size: 32px;
	}
  }

  .swal2-inner{

	.form{
	  &__input{
		border:1px solid  $border-color;

		@include breakpoint(lg){
		  height: 58px;
		}

		&::placeholder{
		  color: $base-200;
		}
	  }
	}
  }
}